import Lang from 'lang.js'
import axios from './services/axios'

import baseSource from '../lang/langs.js';

const lang = new Lang({
	locale: window.locale || 'pt',
	fallback: window.fallback || 'pt',
	messages: baseSource,
})

export const fetchFromLocalStoreOrServer = async () => {
	if (localStorage in window) {
		try {
			const source = JSON.parse(localStorage.getItem('source'))
			if (source) {
				const locale = JSON.parse(localStorage.getItem('locale'))
				const fallback = JSON.parse(localStorage.getItem('fallback'))
				lang.setMessages(source)
				lang.setLocale(locale)
				lang.setFallback(fallback)
				return Promise.resolve(lang)
			} else {
				await updateContentFromServer()
			}
		} catch (e) {
			console.error('error update translator')
		}
	} else {
		await updateContentFromServer()
	}
}

export const updateContentFromServer = async () => {
	try {
		const { data: { source } } = await axios.get('/api/fetch-source-translator', {
			params: {
				locale: window.locale
			}
		})
		lang.setMessages(source)
		return Promise.resolve(source);
	} catch (e) {
		console.error('Error fetch content for translator', e)
		return Promise.reject(e)
	}
}

window.lang = lang
export default { lang, updateContentFromServer, fetchFromLocalStoreOrServer }
